import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./firebase";
import firebase from "firebase/compat/app";

import VueFirestore from "vue-firestore";

import searchComponent from "@/components/search";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";

import i18n from "./i18n";
import DisableAutocomplete from "./vue-disable-autocomplete";

Vue.use(DisableAutocomplete);

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function(msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

Vue.component("search", searchComponent);

Vue.use(VueFirestore);
Vue.use(firebase);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
