<template>
  <v-text-field
    clearable
    filled
    v-if="!hideSearch"
    rounded
    v-model="localSearch"
    hide-details
    prepend-inner-icon="fa-search"
    solo-inverted
    style="border-radius: 15px !important"
    flat
    block
    height="50"
    :label="`Buscar`"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "search",
  props: ["name", "loading"],
  data() {
    return {
      localSearch: "",
    };
  },

  methods: {
    ...mapActions(["handleSearch"]),
  },

  computed: {
    ...mapState(["hideSearch"]),
  },

  watch: {
    localSearch(data) {
      this.handleSearch(data);
    },
  },
};
</script>