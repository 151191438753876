<template>
  <v-app>
    <div v-if="loading" class="loading">
      <img :src="logo" />
    </div>

    <v-app-bar
      v-if="isMobile && userDoc"
      elevation="0"
      absolute
      color="#008bbf"
      dark
    >
      <v-toolbar-title>
        <img style="width: 30%" class="mt-2" :src="logo2" alt="" srcset="" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon color="white" @click="sideBar = !sideBar" />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      width="320"
      app
      :permanent="true"
      :absolute="isMobile"
      :bottom="isMobile"
      :temporary="isMobile"
      dark
      class="navigation-d"
      key="app_nav"
      v-if="navBar && sideBar"
    >
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <img style="width: 150px; cursor: pointer" :src="logo2" />
      </div>

      <menu-render v-if="!loading" :systemAdministrator="systemAdministrator" />

      <template v-slot:append>
        <div class="pa-2 pt-1" style="background-color: rgba(255, 255, 255, 0)">
          <v-btn
            @click="logOut"
            color="rgb(71, 71, 71)"
            class="mb-1"
            style="padding: 14px !important; border-radius: 10px"
            block
            large
            height="50"
            :elevation="0"
          >
            <v-icon>fa-sign-out-alt</v-icon>
            <span v-if="!mini" class="ml-2 font-weight-bold">
              {{ $tc("app.logout") }}
            </span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app flat absolute fixed color="#fff" v-if="!isMobile && userDoc">
      <v-spacer />

      <search :loading="loading" />
      <v-spacer />

      <span>
        <i style="color: gray" class="fas fa-file-pdf"></i>
        <span
          style="cursor: pointer; color: gray"
          @click="goToResources"
          class="mr-5 ml-3 font-weight-bold"
        >
          {{ $tc("app.resources") }}
        </span>
      </span>

      <span v-if="$store.state.group">
        <i style="color: gray" class="fas fa-layer-group"></i>
        <span style="color: gray" class="ml-5 font-weight-bold"
          >{{ $store.state.group ? $store.state.group.name : "group name" }}
        </span>
      </span>
      <!-- <v-spacer /> -->

      <language-changer />
    </v-app-bar>

    <!-- -->

    <v-main style="overflow: hidden" :class="userDoc ? 'mt-8' : ''">
      <transition-page>
        <router-view v-if="!loading" />
      </transition-page>
    </v-main>

    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
    >
      {{ snackbarText }}
    </v-snackbar>

    <v-dialog
      persistent
      max-width="400px"
      transition="dialog-bottom-transition"
      v-if="tokenDialog"
      v-model="tokenDialog"
    >
      <token-input
        ref="tokenComponent"
        @cancel="tokenDialog = false"
        @insertedToken="verifyToken"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import mobile from "is-mobile";
import { db, fb } from "@/firebase";
import { mapState, mapActions } from "vuex";
import TransitionPage from "./components/TransitionPage";
import tokenInput from "./components/tokenInput";
// import i18n from "./i18n";
import languageChanger from "@/components/language-changer";
import menuItem from "@/components/menu-item";
import menuRender from "@/components/menu";

export default {
  name: "App",
  components: {
    TransitionPage,
    tokenInput,
    languageChanger,
    menuItem,
    menuRender,
  },
  data: () => ({
    version: "0.0.1",
    drawer: null,
    mini: false,
    group: null,
    sideBar: false,
    userSnapshot: "",
    collapseOnScroll: true,
    logo: require("@/assets/wink-logo.svg"),
    logo2: require("@/assets/wink-logo-wh.svg"),

    groupsResult: null,
    loading: true,
    snackbar: false,
    snackbarText: null,
    tokenDialog: false,
    pathRoles: {
      sa: "/media/all",
      su: "/media/all",
      ed: "/media/all",
      de: "/agency",
    },
    panel: null,
    // loading: true,
  }),

  watch: {
    userSnapshot(user) {
      this.handleUserDoc(user);

      if ((user.hasOwnProperty("active") && !user.active) || user.deleted) {
        this.logOut();
      }
    },

    $route(e) {
      if (this.isMobile) {
        this.sideBar = false;
      }
    },
    groupsResult(data) {
      this.handleGroup(data[0]);
    },
  },

  computed: {
    ...mapState([
      "selectedApp",
      "user",
      "userDoc",
      "group",
      "appFrame",
      "navBar",
    ]),

    usedStorage() {
      if (this.group.usedStorage < 1024)
        return `${this.group.usedStorage.toFixed(2)} MB / ${
          this.group.totalStorage
        } MB`;
      else
        return `${(this.group.usedStorage / 1024).toFixed(2)} GB / ${(
          this.group.totalStorage / 1024
        ).toFixed(2)} GB`;
    },

    isMobile() {
      return mobile();
    },
  },
  mounted() {
    this.checkLanguage();

    if (!this.isMobile) {
      this.sideBar = true;
    }
    fb.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        fb.auth()
          .currentUser.getIdTokenResult()
          .then((result) => {
            this.systemAdministrator = result.claims.systemAdministrator
              ? true
              : false;
          });

        await this.$binding(
          "groupsResult",
          db.collection("groups").where("users", "array-contains", authUser.uid)
        );

        if (this.groupsResult && this.groupsResult.length == 0) {
          this.snackbarText = this.$t("app.no_linked");
          this.snackbar = true;
          this.logOut();

          setTimeout(() => {
            location.reload();
          }, 2000);
        } else {
          await this.$binding(
            "userSnapshot",
            db.collection("users").doc(authUser.uid)
          );

          const groupId = this.groupsResult[0]
            ? this.groupsResult[0][".key"]
            : null;
          this.handleGroupId(this.groupsResult[0][".key"]);
          this.handleUser(authUser);

          if (this.$router.history.current.fullPath == "/login") {
            this.$router
              .push({ path: this.pathRoles[this.userSnapshot.role] })
              .catch((err) => {
                err;
              });
          }

          if (
            !this.$router.history.current.meta.allowedRoles.includes(
              this.userSnapshot.role
            )
          ) {
            this.$router
              .push({ path: this.pathRoles[this.userSnapshot.role] })
              .catch((err) => {
                err;
              });
          }

          this.loading = false;
        }
      } else {
        this.loading = false;

        if (this.$router.history.current.fullPath == "/register") {
          // this.$router.push({ path: "/register" });
        } else if (
          this.$router.history.current.fullPath != "/login" &&
          this.$router.history.current.fullPath != "/register"
        )
          this.$router.push({ path: "/login" });
      }
    });
  },

  methods: {
    ...mapActions([
      "checkLanguage",
      "handleUserDoc",
      "handleGroup",
      "handleGroupId",
      "handleUser",
    ]),
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.handleUserDoc(null);
          })
          .catch(function (error) {});
      }
    },
    goToResources() {
      if (this.$router.history.current.fullPath != "/resources")
        this.$router.push("/resources");
    },
  },
};
</script>

<style src="./assets/css/animate.css"></style>


<style lang="scss">
@import "./responsive";

@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik-Regular";
  src: local("Graphik-Regular"),
    url(./assets/fonts/Graphik-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Graphik-Semibold";
  src: local("Graphik-Semibold"),
    url(./assets/fonts/Graphik-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

body .v-application {
  font-family: "nexa" !important;
}

body .v-application {
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .headline,
  .title,
  .subtitle-1,
  .subtitle-2,
  .body-1,
  .body-2,
  .caption,
  .overline {
    font-family: "nexa" !important;
  }
}

.v-card__title {
  word-break: normal !important;
}

.cardBox {
  background-color: white;
  padding: 8px 4px !important;
  flex-grow: 0 !important;
  width: 100%;
}

// .v-dialog {
//   border-radius: 35px !important;
// }

.v-snack__wrapper {
  border-radius: 35px !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.v-btn--rounded {
  font-weight: bold !important;
  text-transform: unset !important;
}

.disclaimer-text {
  font-size: 14px;
  font-weight: 500;

  span {
    display: inline-block;
    line-height: 13px;
    font-weight: normal;
    font-size: 12px;
  }
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px !important;
  padding: 0 14px !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-input__append-inner {
  margin-right: 10px;
}

.v-navigation-drawer__border {
  display: none;
}

// .v-application--is-ltr .v-list-item__action:first-child,
// .v-application--is-ltr .v-list-item__icon:first-child {
//   margin-right: 6px !important;
// }

.v-icon.v-icon {
  font-size: 16px !important;
}

.titleBasa svg {
  position: relative;
  height: 32px;
  top: 2px;
  display: inline-block;
}

// .v-list-item__avatar {
//   margin-bottom: 5px !important;
//   margin-top: 5px !important;
// }

.v-date-picker-table .v-btn {
  border-radius: 28px !important;
}

.v-picker__actions.v-card__actions .row.no-gutters {
  padding: 0 10px;
}

.v-tooltip__content {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-radius: 25px !important;
}

.v-data-table-header__icon {
  margin: 0 4px !important;
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 60px !important;
}
.v-autocomplete__content.v-menu__content {
  border-radius: 17px !important;
}

// .v-list-item--disabled {
//   opacity: 0.3 !important;
// }

// .v-list-item--disabled .v-avatar.v-list-item__avatar img {
//   opacity: 0.8 !important;
//   filter: grayscale(100%);
// }

// .theme--light.v-list {
//   background: #e6e6e6 !important;
// }
// .theme--dark.v-list {
//   background: #111111 !important;
// }

.v-snack__content {
  justify-content: center !important;
}

.submenu-items {
  width: 100%;
}

.logo-menu {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  margin-left: -10px;
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(83, 83, 83);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(83, 83, 83) !important;
  border-radius: 10px;
}

// .v-list--rounded .v-list-item,
// .v-list--rounded .v-list-item::before,
// .v-list--rounded .v-list-item > .v-ripple__container {
//   border-radius: 10px !important;
//   display: flex;
//   justify-content: center;
// }

.control-input {
  border: 1px solid rgba(90, 89, 89, 0.315);
  border-radius: 50px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: black;
}

.control-input-number {
  border: 1px solid rgba(90, 89, 89, 0.315);
  border-radius: 50px;
  width: 100% !important;
  padding: 10px;
  margin-top: 10px;
}

.control-input:focus {
  outline: none !important;
}

.sticky {
  position: fixed !important;
  top: 65px !important;
  z-index: 2 !important;
  transition: visibility 0s, opacity 0.2s linear;
}

.v-btn .v-btn__content {
  text-transform: initial !important;
}

.screen-width {
  width: 80% !important;
}

.main-container {
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .v-card__text {
    padding: 0 !important;
  }

  .container {
    padding: 0 !important;
  }

  .screen-width {
    width: 100% !important;
  }
}

textarea {
  padding-left: 20px !important;
}

.save-btn {
  border-radius: 50px !important;
  color: white !important;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  border-radius: 10px !important;
}

// .v-list-item--link {
//   border-radius: 50px;
// }

.v-card {
  border-radius: 15px !important;

  @include responsive(mobile) {
    border-radius: 0 !important;
  }
}

.v-dialog {
  border-radius: 15px !important;

  @include responsive(mobile) {
    margin: 0 !important;
    border-radius: 0 !important;
    overflow-x: hidden;
  }
}

label,
h3,
p {
  color: black;
}

.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 45px !important;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box
  .v-select__selections,
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
  .v-select__selections {
  min-height: 50px !important;
}

.business-active {
  margin-left: -10px;
}

.page-title {
  // color: rgb(71, 71, 71);
  color: #008bbf;
  font-weight: bold;
  font-size: 30px;
  // margin-left: 10px;
  padding: 0;
  margin: 0;
}
.title-hr {
  width: 95%;
  height: 0.3px;
  background-color: white;
}

.video-card-actions {
  display: flex !important;
  align-items: center;
  justify-content: space-around;
}

.two-card-actions {
  display: flex !important;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: space-around;
}

.v-btn--fab.v-size--large {
  bottom: 20px;
  position: fixed;
  right: 20px;
}

.nowrap-text {
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
}
input[type="color"] {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  padding: 0;
  padding-top: 5px;
  width: 100%;
}
.error-validation {
  color: rgb(107, 0, 0);
  position: relative;
  top: -20px;
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px !important;
}

.theme--dark.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: rgb(133 136 138) !important;
}

.statistics {
  position: absolute;
  display: grid;
  grid-template-columns: auto auto auto auto;
  color: gray;
  right: 20px;
  top: 10px;
  align-items: center;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: #008bbf !important;
}

.buttons {
  color: white !important;
}

// .v-list-item--active {
//   color: #515151 !important;
// }

.menu-title {
  color: rgb(133 136 138);
  font-size: 16px;
  margin-left: 15px;
  font-family: "nexa" !important;
  font-weight: bold;
}

.v-expansion-panel::before {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

#radio-file + label {
  left: -60vw;
  background: #4285f4;
  animation-delay: 0s;
}
#radio-file + label:before {
  transform: scale(0);
}
#radio-file:checked + label:before {
  transform: scale(1);
  transition: all 0.4s;
}

@media only screen and (max-width: 600px) {
  .page-title {
    margin-top: 6vh;
    margin-left: 5px;
  }
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

.v-dialog--fullscreen {
  overflow: hidden !important;
  border-radius: 0 !important;
}

.vdr {
  border: 0 !important;
}

.grouper {
  border: 1px solid rgba(128, 128, 128, 0.349);
  margin: 10px;
  padding: 8px;
  border-radius: 10px;
  margin-top: 20px;
}

.align-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .buttons {
    @include responsive(mobile) {
      margin: 5px !important;
      // margin-right: 0 !important;
    }
  }

  .v-select {
    @include responsive(mobile) {
      margin: 5px !important;
    }
  }

  @include responsive(mobile) {
    // display: grid;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end !important;
    margin-right: 5px;
  }
}

.align-center {
  display: flex;
  justify-content: center;
}

.centered {
  text-align: center;
}

// .theme--dark.v-icon {
//   color: rgb(133, 136, 138) !important;
// }

// .close-hover {
//   border-radius : 30px!important
// }
.fa-chevron-down,
.fa-caret-down {
  color: rgb(133, 136, 138) !important;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(71, 71, 71); //#008bbf;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20%;

    @include responsive(mobile) {
      width: 60%;
    }
  }
}

.theme--light.v-tabs-items {
  width: 100%;
}

.navigation-d {
  background-color: #008bbf !important;
  color: white !important;
  padding: 10px;
  border-radius: 20px;
  margin: 10px;
  height: 98% !important;

  @include responsive(mobile) {
    padding: 0;
    border-radius: 0;
    margin: 0;
    height: 100vh !important;
  }
}

.v-list-group__header__append-icon i {
  color: #fff !important;
}

.header-title-actions {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 40px;

  @include responsive(mobile) {
    padding-left: 10px;
  }
}

.margin-content {
  margin-top: 80px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
