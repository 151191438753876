<template>
  <v-card class="pa-3">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline">{{ $tc("token.title") }}</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mt-5">
      {{ $tc("token.hint") }}
    </v-card-subtitle>
    <v-card-text>
      <v-text-field
        v-model="token"
        label="Token"
        v-on:keyup.enter="submit"
        @keypress="maxLength($event, token)"
        rounded
        filled
        autofocus
        clearable
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn :loading="loading" block large @click="submit" color="buttons">{{
        $tc("button.accept")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Token",
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
      token: "",
    };
  },
  methods: {
    submit() {
      if (!this.token || this.token.length != 6) {
        this.snackbarText = this.$tc("messages.min_token");
        this.snackbar = true;
      } else {
        this.loading = true;
        this.$emit("insertedToken", this.token);
      }
    },
    cancel() {
      this.loading = false;
    },
    tokenError() {
      this.snackbarText = this.$tc("messages.invalid_token");
      this.snackbar = true;
      this.loading = false;
    },
    handleSuccess() {
      this.loading = false;
      this.token = "";
      this.$emit("cancel");
    },
    maxLength(evt, field) {
      var keycode = event.which;
      if (
        field.length < 6 &&
        evt.shiftKey == false &&
        (keycode == 46 ||
          keycode == 8 ||
          keycode == 37 ||
          keycode == 39 ||
          (keycode >= 48 && keycode <= 57))
      )
        return true;
      else evt.preventDefault();
    },
  },
};
</script>