import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: (to) => {
      return "/media/all";
    },
  },
  // {
  //   path: "/images",
  //   name: "images",
  //   meta: {
  //     appFrame: true,
  //     navBar: true,
  //     basePath: true,
  //     allowedRoles: ["sa", "su", "ed"],
  //   },
  //   component: () => import("../views/Player/Images/images.vue"),
  // },
  {
    path: "/media/all",
    name: "media",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/Player/Media/media.vue"),
  },

  {
    path: "/games/trivia",
    name: "trivia",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/games/trivia/index.vue"),
  },

  {
    path: "/games/roulette",
    name: "roulette",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/games/roulette/list.vue"),
  },
  {
    path: "/resources",
    name: "resources",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () => import("../views/resources.vue"),
  },

  {
    path: "*",
    name: "not-found",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () => import("../views/notFound.vue"),
  },
  {
    path: "/info/profile",
    name: "profile",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () => import("../views/account-information/Profile.vue"),
  },
  {
    path: "/info/users",
    name: "users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa"],
    },
    component: () => import("../views/account-information/users.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      appFrame: false,
      navBar: false,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () => import("../views/Login.vue"),
  },

  // {
  //   path: "/videos",
  //   name: "videos",
  //   meta: {
  //     appFrame: true,
  //     navBar: true,
  //     allowedRoles: ["sa", "su", "ed"],
  //   },
  //   component: () => import("../views/Player/Videos/videos.vue"),
  // },

  {
    path: "/app/playlists",
    name: "playlists",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/Player/Playlists/playlists.vue"),
  },
  {
    path: "/agency",
    name: "agency",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "de"],
    },
    component: () => import("../views/Agencies/agency.vue"),
  },
  {
    path: "/info/screens",
    name: "screens",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa"], //"su", "ed"
    },
    component: () => import("../views/account-information/screens/screens.vue"),
  },
  //directories pages
  {
    path: "/app/directory/products",
    name: "products",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () => import("../views/Directories/Products/products.vue"),
  },
  {
    path: "/app/directory/characteristics",
    name: "characteristics",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () =>
      import("../views/Directories/Characteristics/characteristics.vue"),
  },
  {
    path: "/app/directory/categories",
    name: "categories",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () => import("../views/Directories/Categories/categories.vue"),
  },
  {
    path: "/app/directory/subcategories",
    name: "subcategories",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed", "de"],
    },
    component: () =>
      import("../views/Directories/Subcategories/subcategories.vue"),
  },
  {
    path: "/app/directory/banners",
    name: "banners",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/Directories/Banners/banners.vue"),
  },

  //layout pages
  {
    path: "/app/zones",
    name: "zones",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/Zones/Zones.vue"),
  },

  {
    path: "/app/forms",
    name: "forms",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/forms/form.vue"),
  },

  //system administrator
  {
    path: "/admin/groups",
    name: "Groups",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/administration/groups/list.vue"),
  },
  {
    path: "/admin/all_users",
    name: "All Users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/administration/users/users.vue"),
  },

  //survey

  {
    path: "/app/survey",
    name: "survey",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/survey/list.vue"),
  },

  //register
  {
    path: "/register",
    name: "Register",
    meta: {
      appFrame: false,
      navBar: false,
    },
    component: () => import("../views/administration/register.vue"),
  },

  {
    path: "/offerings/list",
    name: "offerings-list",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/offerings/list.vue"),
  },

  {
    path: "/offerings/amounts",
    name: "offerings-amounts",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sa", "su", "ed"],
    },
    component: () => import("../views/offerings/amounts.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
