<template>
  <v-select
    item-text="name"
    item-value="value"
    style="max-width: 80px"
    class="ma-0 pa-0 ml-7"
    :items="langs"
    hide-details
    @change="handleChanger"
    v-model="$i18n.locale"
  >
    <template v-slot:selection="data">
      <img width="30" :src="data.item.icon" :alt="data.item.name" />
    </template>

    <template v-slot:item="data">
      <img width="30" :src="data.item.icon" :alt="data.item.name" />
    </template>
  </v-select>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Language Changer",
  computed: {
    langs() {
      return [
        {
          name: this.$t("languages.spanish"),
          value: "es",
          icon: require("../assets/locales/spain.png"),
        },
        {
          name: this.$t("languages.english"),
          value: "en",
          icon: require("../assets/locales/english.png"),
        },
      ];
    },
  },
  methods: {
    ...mapActions(["persistentLanguage"]),

    handleChanger(e) {
      this.persistentLanguage(e);
    },
  },
};
</script>