import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
import VuexPersist from "vuex-persist";
import i18n from "../i18n";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "wink",
  storage: window.localStorage,
  modules: ["language"],
});

export default new Vuex.Store({
  state: {
    search: "",
    searchTerm: "",
    appFrame: false,
    navBar: false,
    user: {},
    userIsValid: true,
    visibleSearch: true,
    selectedApp: "",
    userDoc: null,
    groupId: "",
    language: null,
    group: null,
    hideSearch: false,
  },
  getters: {
    userIsValid: (state) => state.userIsValid,
    userType: (state) => state.user.type,
  },
  mutations: {
    setUser(state, target) {
      state.user = target;

      if (state.user.type == "sudo" && state.user.businesses)
        state.user.businesses = [];
    },
    setBusinessesList(state, target) {
      state.businessesList = target;
    },
    setUserValidity(state, target) {
      state.userIsValid = target;
      setTimeout(() => {
        state.userIsValid = true;
      }, 3000);
    },
    setSearch(state, target) {
      state.search = target;
    },
    setSearchTerm(state, target) {
      state.searchTerm = target;
    },
    setDarkMode(state, target) {
      state.darkmode = target;
    },
    setAppFrame(state, target) {
      state.appFrame = target;
    },
    setNavBar(state, target) {
      state.navBar = target;
    },
    setEnvironment(state, target) {
      state.environment = target;
    },
    setSelectedApp(state, target) {
      state.selectedApp = target;
      router.push({ path: `/images` });
    },
    seti18nLanguage(state, target) {
      if (!state.language) state.language = i18n.locale;
      else i18n.locale = state.language;
    },
    setPersistentLanguage(state, target) {
      state.language = target;
      i18n.locale = target;
    },
    setUserDoc(state, target) {
      state.userDoc = target;
    },
    setGroup(state, target) {
      state.group = target;
    },
    setGroupId(state, target) {
      state.groupId = target;
    },

    setUser(state, target) {
      state.user = target;
    },
    setSearch(state, target) {
      state.search = target;
    },
    hSearch(state, target) {
      state.hideSearch = target;
    },
  },
  actions: {
    checkLanguage: ({ commit, state }) => {
      commit("seti18nLanguage", null);
    },
    persistentLanguage: ({ commit, state }, language) => {
      commit("setPersistentLanguage", language);
    },
    handleUserDoc: ({ commit, state }, data) => {
      commit("setUserDoc", data);
    },

    handleGroup: ({ commit, state }, data) => {
      commit("setGroup", data);
    },
    handleGroupId: ({ commit, state }, data) => {
      commit("setGroupId", data);
    },

    handleUser: ({ commit, state }, data) => {
      commit("setUser", data);
    },
    handleSearch: ({ commit, state }, data) => {
      commit("setSearch", data);
    },
    cleanSearch: ({ commit, state }) => {
      commit("setSearch", "");
    },

    hide_search: ({ commit, state }, data) => {
      commit("hSearch", data);
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
