<template>
  <v-list v-model="group" nav expand class="mt-5">
    <div v-for="(item, id) of items" :key="id">
      <!-- MENU SIN HIJOS -->
      <v-list-item
        v-on:click="$emit('handleClick', item)"
        :to="item.link"
        v-if="!item.children"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{
            $tc(`app.menu.${item.title}`)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- MENU CON HIJOS-- SE AGRUPA -->
      <v-list-group :group="item.group" v-else :key="item.title">
        <template v-slot:activator>
          <!-- <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon> -->

          <v-list-item-title style="color: white !important"
            >{{ $tc(`app.menu.${item.title}`) }}
          </v-list-item-title>
        </template>

        <div v-for="child in item.children" :key="child.title">
          <div v-if="child.type == 'divide'">
            <hr style="border: 0.5px solid #878080" />
          </div>

          <div v-else>
            <v-list-item
              v-on:click="$emit('handleClick', child)"
              :to="child.link"
              class="ml-8"
              v-if="!child.children"
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $tc(`app.menu.${child.title}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              :group="child.group"
              :key="child.title"
              class="ml-3"
              v-else
              v-model="child.active"
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $tc(`app.menu.${child.title}`)
                  }}</v-list-item-title>
                </v-list-item-content>
                <!-- <v-list-item-icon>
                  <v-icon class="font-weight-bold">
                    {{
                      child.active ? "fal fa-chevron-up" : "fal fa-chevron-down"
                    }}</v-icon
                  >
                </v-list-item-icon> -->
              </template>

              <v-list-item
                v-on:click="$emit('handleClick', e)"
                :to="e.link"
                class="ml-5"
                v-for="(e, i) in child.children"
                :key="i"
                link
              >
                <v-list-item-icon>
                  <v-icon>{{ e.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $tc(`app.menu.${e.title}`) }}
                  </v-list-item-title>
                </v-list-item-content>
                <!-- <v-list-item-title>{{
                  $tc(`app.menu.${e.title}`)
                }}</v-list-item-title> -->
              </v-list-item>
            </v-list-group>
          </div>
        </div>
      </v-list-group>
      <!-- </div> -->
    </div>
  </v-list>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "menu-d",
  props: ["systemAdministrator"],
  data() {
    return {
      systemAdministrator: false,
      group: null,
      adminMenu: [
        {
          title: "system_administrator",
          allowedRoles: ["sa", "su", "ed"],
          group: "/admin",
          children: [
            {
              icon: "fa-users",
              title: "users",
              link: "/admin/all_users",
              disabled: false,
              allowedRoles: ["sa", "su", "ed"],
            },
            {
              icon: "fa-users-class",
              title: "groups",
              link: "/admin/groups",
              disabled: false,
              allowedRoles: ["sa", "su", "ed"],
            },
          ],
        },
      ],
      menu: [
        {
          title: "account_info",
          allowedRoles: ["sa", "su", "ed", "de"],
          group: "/info",
          children: [
            {
              icon: "fa-desktop",
              title: "my_screens",
              link: "/info/screens",
              allowedRoles: ["sa"], //"su", "ed"
              disabled: false,
            },
            {
              icon: "fa-user",
              title: "my_account",
              link: "/info/profile",
              disabled: false,
              allowedRoles: ["sa", "su", "ed", "de"],
            },
            {
              icon: "fa-users",
              title: "users",
              link: "/info/users",
              allowedRoles: ["sa"],
              disabled: false,
            },
          ],
        },
        {
          title: "multimedia_content",
          allowedRoles: ["sa", "su", "ed"],
          group: "/media",
          children: [
            {
              icon: "fa-images",
              title: "media",
              link: "/media/all",
              disabled: false,
              allowedRoles: ["sa", "su", "ed"],
            },
          ],
        },
        {
          title: "applications",
          allowedRoles: ["sa", "su", "ed", "de"],
          //   isGrouper: true,
          group: "/app",
          children: [
            {
              icon: "fa-play-circle",
              title: "playlists",
              allowedRoles: ["sa", "su", "ed"],
              link: "/app/playlists",
              disabled: false,
            },
            {
              icon: "fa-th-large",
              allowedRoles: ["sa", "su", "ed"],
              title: "zones_areas",
              link: "/app/zones",
              disabled: false,
            },
            {
              icon: "fa-poll-h",
              allowedRoles: ["sa", "su", "ed"],
              title: "survey",
              link: "/app/survey",
              disabled: false,
            },

            
            {
              title: "touch_directories",
              allowedRoles: ["sa", "su", "ed", "de"],
              group: "/directory",
              children: [
                {
                  icon: "fa-clipboard-list",
                  title: "directory_start_menu",
                  allowedRoles: ["sa", "su", "ed", "de"],
                  link: "/app/directory/categories",
                  disabled: false,
                },

                {
                  icon: "fa-clipboard-list",
                  title: "directory_sub_menu",
                  allowedRoles: ["sa", "su", "ed", "de"],
                  link: "/app/directory/subcategories",
                  disabled: false,
                },

                {
                  icon: "fa-list",
                  title: "product_features",
                  allowedRoles: ["sa", "su", "ed", "de"],
                  link: "/app/directory/characteristics",
                  disabled: false,
                },
                {
                  icon: "fa-sitemap",
                  title: "products_services_staff",
                  link: "/app/directory/products",
                  allowedRoles: ["sa", "su", "ed", "de"],
                  disabled: false,
                },
                {
                  icon: "fa-bookmark",
                  title: "advertising_banner",
                  link: "/app/directory/banners",
                  allowedRoles: ["sa", "su", "ed"],
                  disabled: false,
                },
              ],
            },
          ],
        },
        {
          title: "games",
          allowedRoles: ["sa", "su", "ed"],
          group: "/games",
          children: [
            {
              icon: "fa-question",
              title: "trivia",
              link: "/games/trivia",
              disabled: false,
              allowedRoles: ["sa", "su", "ed"],
            },
            {
              icon: "fa-gamepad",
              title: "roulette",
              link: "/games/roulette",
              disabled: false,
              allowedRoles: ["sa", "su", "ed"],
            },
          ],
        },
        {
          icon: "fa-building",
          title: "agencies_stores",
          link: "/agency",
          allowedRoles: ["sa", "su", "de"],
          disabled: false,
        },
       
      ],
    };
  },
  methods: {
    // ...mapActions(["addMenu"]),
  },
  computed: {
    ...mapState(["userDoc"]),
    items() {
      console.log(this.userDoc.role);
      const menu = this.menu.filter((item) =>
        item.allowedRoles.includes(this.userDoc.role)
      );

      menu.forEach((item) => {
        if (item.children) {
          item.children = item.children.filter((child) =>
            child.allowedRoles.includes(this.userDoc.role)
          );

          item.children.forEach((child) => {
            if (child.children) {
              child.children = child.children.filter((el) =>
                el.allowedRoles.includes(this.userDoc.role)
              );
            }
          });
        }
      });

      return this.systemAdministrator ? [...this.adminMenu, ...menu] : menu;
    },
  },
};
</script>


<style scoped>
.v-list-item__title {
  color: rgb(255, 255, 255) !important;
}

.theme--dark.v-icon {
  color: rgb(255, 255, 255) !important;
}

.v-list-item--active {
  /* color: rgba(117, 116, 116, 0.933); */
}

.search {
  background-color: white;
}
</style>