import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/app-check";

var config = {
  apiKey: "AIzaSyDhxuDgP37u6oQHw7bUvDSXl_j6Fvsu8i4",
  authDomain: "beanage-hn.firebaseapp.com",
  projectId: "beanage-hn",
  storageBucket: "beanage-hn.appspot.com",
  messagingSenderId: "347213272084",
  appId: "1:347213272084:web:dcd60975ecd64670908301",
};

firebase.initializeApp(config);
firebase.auth().languageCode = "es";

const appCheck = firebase.appCheck();

if (window.origin.includes("localhost")) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.VUE_APP_APP_CHECK_DEBUG_TOKEN;
  // firebase.functions().useEmulator("localhost", "5001");
}

appCheck.activate(process.env.VUE_APP_APP_CHECK_TOKEN, true);

export const db = firebase.firestore();
export const fb = firebase;
