<template>
  <div>
    <!-- There are 3 types of items in the menu,
        1. Expansion panels, these have children. Example: account information.
        2. Groupers: example: applications.
        3. Individuals: last level of the menu. Example: agencies or stores.
    -->

    <!-- expansion panel -->
    <v-expansion-panel
      v-if="item.children && item.children.length && !item.isGrouper"
    >
      <v-expansion-panel-header
        style="
          color: rgb(133 136 138);
          font-size: 16px !important;
          font-family: 'nexa' !important;
        "
        color="#eeeeee"
        >{{ $tc(`app.menu.${item.name}`) }}
      </v-expansion-panel-header>

      <v-expansion-panel-content
        v-for="(child, index) in item.children"
        :key="index"
        color="#eeeeee"
      >
        <v-list-item
          :to="`${child.link}`"
          @click="isMobile ? (sideBar = false) : ''"
          class="mb-5"
          style="padding-left: 30px"
        >
          <v-list-item-icon>
            <v-icon
              class="mr-5"
              style="font-size: 18px !important; color: rgb(133 136 138)"
              large
              >{{ child.icon }}</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              style="font-size: 16px !important; color: rgb(133 136 138)"
              v-text="$tc(`app.menu.${child.name}`)"
            >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <!-- Grouper element -->
    <div class="grouper" v-if="item.isGrouper">
      <small style="color: rgb(133, 136, 138)" class="pa-3 font-weight-bold">{{
        $tc(`app.menu.${item.name}`)
      }}</small>

      <div style="width: 100%" v-for="(element, i) in item.children" :key="i">
        <v-expansion-panel v-if="element.children && element.children.length">
          <v-expansion-panel-header
            style="
              color: rgb(133 136 138);
              font-size: 16px !important;
              font-family: 'nexa' !important;
            "
            color="#eeeeee"
            >{{ $tc(`app.menu.${element.name}`) }}</v-expansion-panel-header
          >
          <v-expansion-panel-content
            v-for="(child, index) in element.children"
            :key="index"
            color="#eeeeee"
          >
            <v-list-item
              :to="`${child.link}`"
              @click="isMobile ? (sideBar = false) : ''"
              class="mb-5"
              style="padding-left: 30px"
            >
              <v-list-item-icon>
                <v-icon
                  class="mr-5"
                  style="font-size: 18px !important; color: rgb(133 136 138)"
                  large
                  >{{ child.icon }}</v-icon
                >
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  style="font-size: 16px !important; color: rgb(133 136 138)"
                  v-text="$tc(`app.menu.${child.name}`)"
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-list-item
          v-if="element.link && !element.isGrouper"
          :to="`${element.link}`"
          @click="isMobile ? (sideBar = false) : ''"
          class="mb-5"
          style="padding-left: 25px"
        >
          <v-list-item-icon>
            <v-icon
              class="mr-5"
              style="font-size: 18px !important; color: rgb(133 136 138)"
              large
              >{{ element.icon }}</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              style="font-size: 16px !important; color: rgb(133 136 138)"
              v-text="$tc(`app.menu.${element.name}`)"
            >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>

    <!-- Individual element -->
    <v-list-item
      v-if="item.link && !item.isGrouper"
      :to="`${item.link}`"
      @click="isMobile ? (sideBar = false) : ''"
      class="mb-5"
      style="padding-left: 25px"
    >
      <v-list-item-icon>
        <v-icon
          class="mr-5"
          style="font-size: 18px !important; color: rgb(133 136 138)"
          large
          >{{ item.icon }}</v-icon
        >
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title
          style="font-size: 16px !important; color: rgb(133 136 138)"
          v-text="$tc(`app.menu.${item.name}`)"
        >
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "Menu Item",
  props: ["item"],
};
</script>